import React, { useRef } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import BaseLayout from "../components/base-layout";
import AboutOne from "../images/about-us/about-1.jpg";
import AboutTwo from "../images/about-us/about-2.jpg";
import AboutThree from "../images/about-us/about-3.jpg";
import AboutFour from "../images/about-us/about-fama.jpg";
import ArrowLeft from "../images/icons/arrow-left.png";
import ArrowRight from "../images/icons/arrow-right.png";
import MachineOne from "../images/items/machine1.png";
import MachineTwo from "../images/items/machine2.png";
import Serti1 from "../images/serti-1.png";
import Serti2 from "../images/serti-bpom.png";
import Serti5 from "../images/serti-fama.png";
import Serti3 from "../images/serti-halal.png";
import Serti4 from "../images/serti-iso.png";
import PageTitle from "../reusable/page-title";
import { CarouselArrow } from "../styles/global-style";
import {
  Facilitybox,
  FlexEndCol,
  FlexStartCol,
  ImgBox,
  SertiBox,
} from "../styles/lab-style";

const LabPage = () => {
  const labSliderRef = useRef(null);

  var labSliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <React.Fragment></React.Fragment>,
    nextArrow: <React.Fragment></React.Fragment>,
  };

  return (
    <BaseLayout title='About Us' navColor='white'>
      <Container fluid>
        <Row>
          <FlexEndCol sm={6} withBg={true} bgUrl={AboutOne}></FlexEndCol>

          <FlexStartCol sm={6} withBg={true}>
            <div style={{ maxWidth: "75%", color: "#0284C7" }}>
              PT. Wahana Kosmetika Indonesia (PT. WKI) terletak di kota Sidoarjo
              dengan lokasi strategis yang dekat dari manapun memudahkan Anda
              untuk mendistribusikan produk. PT. WKI memiliki tim profesional
              ditunjang dengan alat-alat canggih dan bahan berkualitas untuk
              dapat mengembangkan konsep yang ingin Anda wujudkan menjadi nyata.
            </div>
          </FlexStartCol>
        </Row>

        <Row>
          <FlexEndCol sm={6} withBg={false}>
            <p className='text-right'>2002</p>
          </FlexEndCol>

          <FlexStartCol sm={6} withBg={false}>
            <div style={{ maxWidth: "75%" }}>
              Perusahaan kami berdiri tahun 2002, membuat solusi produk body
              care dan personal care untuk client salon dan hotel-hotel bintang
              lima. Pertama kali perusahaan ini melayani produksi adalah untuk
              brand kosmetik dengan merek Azarine yang termasuk produk resep Spa
              Herbal khas Indonesia.
            </div>
          </FlexStartCol>
        </Row>

        <Row>
          <FlexEndCol sm={6} withBg={true} bgUrl={AboutTwo}>
            2016
          </FlexEndCol>

          <FlexStartCol sm={6} withBg={true}>
            <div style={{ maxWidth: "75%", color: "#0284C7" }}>
              Tahun 2016 merupakan cikal bakal tim research web WKI berdiri,
              didirikan oleh para ilmuwan dan dipimpin oleh direktur kami, Pak
              Brian Tjahyanto, B. Eng (ChemEng) (Honours) yang membawa pembaruan
              ilmiah dan terobosan penggabungan teknologi herbal.
            </div>
          </FlexStartCol>
        </Row>

        <Row>
          <FlexEndCol sm={6} withBg={false}>
            <p className='text-right'>2017</p>
          </FlexEndCol>

          <FlexStartCol sm={6} withBg={false}>
            <div style={{ maxWidth: "75%" }}>
              Semua produk yang kami kembangkan terdaftar di dalam BPOM dan
              pabrik kami pada tahun 2017 mendapatkan sertifikasi CPKB dari
              Badan Pengawas Obat dan Makan Pemerintah Indonesia, yang merupakan
              salah satu sertifikasi penting untuk menunjukkan produk kosmetik
              yang dihasilkan memenuhi standar Internasional dalam aspek
              produksi sampai pengawasan mutu.
            </div>
          </FlexStartCol>
        </Row>

        <Row>
          <FlexEndCol sm={6} withBg={true} bgUrl={AboutThree}>
            2018
          </FlexEndCol>
          <FlexStartCol sm={6} withBg={true}>
            <div style={{ maxWidth: "75%", color: "#0284C7" }}>
              Dengan selesai dibangunnya Pabrik Modern dan berteknologi tinggi
              pada tahun 2018, produksi dipindahkan ke gedung yang baru. Pabrik
              dibangun dengan Standar Internasional dengan integrasi sistem dan
              teknologi otomasi. Pabrik dilengkapi dengan mesin-mesin modern dan
              sumber daya manusia yang kompeten untuk memastikan produk aman dan
              memiliki jaminan kualitas yang baik.
              <SertiBox>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Sertifikasi :{" "}
                </h3>
                <img
                  src={Serti1}
                  style={{ height: "75px", width: "auto", margin: "0 10px" }}
                />
                <img
                  src={Serti2}
                  style={{ height: "75px", width: "auto", margin: "0 10px" }}
                />
                <img
                  src={Serti3}
                  style={{ height: "75px", width: "auto", margin: "0 10px" }}
                />
              </SertiBox>
            </div>
          </FlexStartCol>
        </Row>
        <Row>
          <FlexEndCol sm={6} withBg={false}>
            2020
          </FlexEndCol>
          <FlexStartCol sm={6} withBg={false}>
            <div style={{ maxWidth: "75%" }}>
              Di tahun 2020 kami telah memenuhi syarat untuk memperoleh
              sertifikasi ISO 9001. ISO 9001 merupakan standar bertaraf
              internasional untuk Sertifikasi Sistem Manajemen Mutu, atau bisa
              disebut juga sebagai Sertifikasi Sistem Manajemen Kualitas.
              <SertiBox>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Sertifikasi :{" "}
                </h3>
                <img
                  src={Serti4}
                  style={{ height: "75px", width: "auto", margin: "0 10px" }}
                  title='ISO 9001'
                />
              </SertiBox>
            </div>
          </FlexStartCol>
        </Row>
        <Row>
          <FlexEndCol
            sm={6}
            withBg={true}
            bgUrl={AboutFour}
            style={{ backgroundPosition: "center bottom" }}>
            2022
          </FlexEndCol>
          <FlexStartCol sm={6} withBg={true}>
            <div style={{ maxWidth: "75%", color: "#0284C7" }}>
              Di tahun 2022 kami telah memenuhi syarat untuk memperoleh
              sertifikasi FAMA dari Walt Disney Company. FAMA memungkinan produk
              yang kami rancang dapat menggunakan desain intelektual milik Walt
              Disney seperti Marvel, Pixar dan Star Wars.
              <SertiBox>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Sertifikasi :{" "}
                </h3>
                <img
                  src={Serti5}
                  style={{ height: "75px", width: "auto", margin: "0 10px" }}
                  title='We are Disney FAMA Certified Factory! Disney FAMA certificate confirms that we can manufacture the products bearing intellectual property of The Walt Disney Company and its affiliates, such as Marvel, Pixar and Star Wars-branded products!'
                />
              </SertiBox>
            </div>
          </FlexStartCol>
        </Row>
      </Container>

      <PageTitle title='Facilities and Lab' />
      <Container>
        <Facilitybox>
          <Row className='align-items-center'>
            <Col md='4'>
              <ImgBox>
                <Slider ref={labSliderRef} {...labSliderSettings}>
                  <div>
                    <Image src={MachineOne} alt='brand icon' fluid />
                  </div>
                  <div>
                    <Image src={MachineTwo} alt='brand icon' fluid />
                  </div>
                </Slider>
              </ImgBox>
            </Col>

            <Col md='8'>
              <Row className='align-items-center'>
                <Col sm='1'>
                  <CarouselArrow
                    className='arrow-right'
                    src={ArrowLeft}
                    onClick={() => labSliderRef.current.slickPrev()}
                  />
                </Col>

                <Col sm='10'>
                  {/* <h4>
                      <strong>Mixer Pengemulsi Vakum SS316 200L</strong>
                    </h4> */}
                  <span>
                    Mesin pengemulsi vakum umumnya terbuat dari pengemulsi utama
                    mixer, mixer fase minyak, mixer fase air, sistem vakum,
                    sistem mengangkat hidrolik, sistem miring untuk mixer
                    pengemulsi utama, sistem pemanas dan pendingin, sistem
                    kontrol suhu, sistem kontrol listrik, panel kontrol listrik
                    dll. Tangki pengemulsi utama terdiri dari homogenizer rotor
                    stator kecepatan tinggi dan agitator pusat putar balik dan
                    pengikis dinding. Homogenizer, agitator tengah, dan scraper
                    dinding semuanya dipasang dari bagian atas penutup. Mixer
                    fase minyak dan air memiliki konstruksi yang persis sama.
                    Ada disperser berkecepatan tinggi dipasang dari bagian atas
                    penutup yang membantu melarutkan bahan
                  </span>
                </Col>

                <Col sm='1'>
                  <CarouselArrow
                    className='arrow-right'
                    src={ArrowRight}
                    onClick={() => labSliderRef.current.slickNext()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Facilitybox>
      </Container>
    </BaseLayout>
  );
};

export default LabPage;
