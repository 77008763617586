import { Col } from "react-bootstrap";
import styled from "styled-components";

export const Facilitybox = styled.div`
  margin-bottom: 40px;
  background-color: #CBE9F4;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
  overflow: hidden;
`;

export const ImgBox = styled.div`
  background-color: #fff;
  margin-top: -30px;
  margin-left: -30px;
  margin-bottom: -30px;
  padding: 30px 30px 30px 30px;
  border-radius: 0px 50% 50% 0px;
`;

export const SertiBox = styled.div`
  display: flex;
  margin-top: 30px;
  align-items: center;
  color: #222;
`;

export const FlexEndCol = styled(Col)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "montserrat-bold";
  font-weight: bold;
  font-size: 3.5rem;

  ${({ withBg, bgUrl }) =>
    withBg &&
    `
    background: url(${bgUrl});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFFFFF;
  `}
`;

export const FlexStartCol = styled(Col)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem;

  ${({ withBg }) =>
    withBg &&
    `background-color: #cbe9f4;
    opacity: 0.6;`}
`;




